import React from 'react'
import ReactMarkdown from 'react-markdown'

const Welcome = ({onStart, content}) => {
    return (
        <div className="Page">

                <div className="body container">

                    <ReactMarkdown source={content} escapeHtml={false} />

                </div>

                <div className="footer">
                    <button className="btn btn-secondary btn-next btn-start" onClick={() => {
                        onStart()
                    }}>Start
                    </button>
                </div>
        </div>
    )
}

export default Welcome
