import React, {useState, useEffect} from 'react';
import ReactMarkdown from 'react-markdown'

import './QuestionAnswer.scss'
import Card from "./lib/DraggableCard";
import Cards from "./lib/Cards";
import SimpleCard from "./lib/SimpleCard";


const action = (a) => {
    console.warn('action: ', a)
}

const Wrapper = ({correct_answer, intro, question, explanation, explanation_img, onNext, onAnswer, isLastQuestion}) => {

    const [answer, setAnswer] = useState(null)
    const [clickSlide, setSlideAnimation] = useState(false)
    const [manualSlide, setManualSlide] = useState(null)
    const [visible, setVisible] = useState(null)
    const answered = answer !== null
    const answerCorrect = answer === correct_answer
    const questionCardRef = React.createRef();

    const renderAnswer = () => {
        return (
            <div>
                <div className="correct-indication">
                    {answerCorrect ? <div className="true">Jouw antwoord is juist.</div> : <div className="false">Jouw antwoord is onjuist.</div>}
                    {correct_answer ? <div>De stelling is waar.</div> : <span>De stelling is niet waar.</span>}
                </div>

                <div className="explanation">
                    {answered &&
                        <>
                            {explanation_img && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
                                <img src={explanation_img} alt="" style={{maxWidth: '100%'}}/>
                            </div>}
                        <ReactMarkdown source={explanation}></ReactMarkdown>
                        </>
                    }
                </div>
            </div>
        )
    }

    const questionText = () => {
        return (
            <ReactMarkdown source={question} />
        )
    }

    const doManualSlide = (directionRight) => {
        setSlideAnimation(true)
        const c = questionCardRef.current
        c.panstart()
        c.panmove({deltaX: (c.props.containerSize.x + 105) * (directionRight ? 1 : -1), deltaY: 0})
        setTimeout(() => {
            c.panend()
            setSlideAnimation(false)
        }, 500)
    }

    useEffect(() => {

        if (answered)
            return null

        if (manualSlide !== null)
            doManualSlide(manualSlide)
    }, [manualSlide]);

    useEffect(() => {
        setVisible(true)
    }, [question]);

    useEffect(() => {
        if (answer === null) {
            return
        }
        onAnswer(answerCorrect, answer)
    }, [answer])

    return (
        <Cards onEnd={() => action('end')}
               index={2}
               className={`Page QuestionAnswer ${answered ? (answerCorrect ? 'answered answer-correct' : 'answered answer-incorrect') : ''} ${clickSlide ? 'click-slide' : ''} ${visible ? 'visible' : ''}`}>

            <Card ref={questionCardRef}
                  onSwipeLeft={() => setAnswer(false)}
                  onSwipeRight={() => setAnswer(true)}>
                {/*onSwipeLeft={() => setManualSlide(false)}*/}
                {/*onSwipeRight={() => setManualSlide(true)}>*/}

                <div className="body">
                    {questionText()}
                </div>

                <div className="footer">
                    <button className="btn btn-secondary btn-false" onClick={() => {
                        setManualSlide(false)
                    }}>Niet waar
                    </button>
                    <button className="btn btn-secondary btn-true" onClick={() => {
                        setManualSlide(true)
                    }}>Waar
                    </button>
                </div>

            </Card>

            <SimpleCard
                onSwipeLeft={() => action('swipe left')}
                onSwipeRight={() => action('swipe right')}>

                <div className="body">
                    {questionText()}
                    {renderAnswer()}
                </div>

                <div className="footer">
                    <button className="btn btn-secondary btn-next" onClick={() => {
                        onNext()
                    }}>
                        {isLastQuestion ? 'Jouw resultaat' : 'Volgende vraag'}
                    </button>
                </div>

            </SimpleCard>
        </Cards>
    )
}

export default Wrapper;
