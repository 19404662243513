
export const translate3d = (x, y, r) => {
  const translate = `translate3d(${x}px, ${y}px, 0px) rotate(${r}deg)`
  // const translate = `translate3d(${x}px, ${y}px, 0px) `
  return {
    msTransform: translate,
    WebkitTransform: translate,
    transform: translate
  }
}

export const DIRECTIONS = [ 'Right', 'Left', 'Top', 'Bottom' ]

