// import "core-js/stable";
// import "regenerator-runtime/runtime";

import 'react-app-polyfill/ie11'



import React, {useState, useEffect} from 'react';
import './App.scss';
import QuestionAnswer from "./QuestionAnswer";
import 'bootstrap';
import Welcome from "./Welcome";
import axios from 'axios';

import logoImg from './img/logo.png';

import imgIconWrong from './img/icon_fout.svg'
import imgIconRight from './img/icon_goed.svg'
import FinishPage from "./FinishPage";


// axios.defaults.withCredentials = true;

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

const setCssProperty = (k, v) => {
    document.documentElement.style.setProperty(`--${k}`, v)
}

let apiUrl = process.env.REACT_APP_API_URL || '/api'


/*
const questions = [
    {
        title: 'Stelling 1',
        question: 'Windmolenparken en zonneweides kost veel ruimte, namelijk bijna net zoveel als we jaarlijks kwijt zijn aan uitbreiding van de woongebieden, wegen en natuur.',
        correctAnswer: true,
        explanation: 'De capaciteit van windmolens is de laatse jaren sterk toegenomen. Het aantal van 2.000 is een convervatieve schatting. In Noord-Holland ligt het aantal woningen die door een windmolens van elektriciteit kan worden voorzien hoger. De reden: het waait hier meer en harder dan in veel andere provincies.'
    },
    {
        title: 'Stelling 2',
        question: 'Question 2',
        correctAnswer: false,
        explanation: 'Explanation 2'
    },
    {
        title: 'Stelling 3',
        question: 'Question 3',
        correctAnswer: false,
        explanation: 'Explanation 3'
    },
    {
        title: 'Stelling 4',
        question: 'Question 4',
        correctAnswer: false,
        explanation: 'Explanation 4'
    },
]
*/

// using jQuery
function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}


const submitResponse = async (questionId, answer) => {
    await axios.post(`${apiUrl}/response/`, {
        questionId, answer

    }, {
        headers: {
            'X-CSRFToken': getCookie('csrftoken')
        }
    })
}

function App() {

    const [qaIndex, setQAIndex] = useState(null)
    const [hidePage, setHidePage] = useState(false)
    const [correctAnswers, setCorrectAnswers] = useState([])

    const [questions, setQuestions] = useState([])
    const [staticTexts, setStaticTexts] = useState([])
    const [scoreTexts, setScoreTexts] = useState([])
    const [responseData, setResponseData] = useState([])

    const fetchQuestions = async () => {

        if (questions.length === 0) {

            const get = async () => {
                const r2 = await axios.get(`${apiUrl}/staticTexts/`)
                const texts = {}
                // eslint-disable-next-line
                r2.data.map((v) => {
                    texts[v.handle] = v.content
                })
                setStaticTexts(texts)

                const r1 = await axios.get(`${apiUrl}/questions/`)
                setQuestions(r1.data)

                const r3 = await axios.get(`${apiUrl}/scoreTexts/`)
                setScoreTexts(r3.data)

                const r4 = await axios.get(`${apiUrl}/response/`)
                setResponseData(r4.data)

            }
            get()

        }
    }

    useEffect(() => {
        fetchQuestions()

    })

    useEffect(() => {

        const answersCorrect = correctAnswers.filter(v => !!v).length
        setCssProperty('progress-correct', Math.ceil(answersCorrect / questions.length * 100))

        const answersInCorrect = correctAnswers.filter(v => !v).length
        setCssProperty('progress-fail', Math.ceil(answersInCorrect / questions.length * 100))

    }, [correctAnswers, questions.length])

    const question = questions[qaIndex]

    const onAnswer = React.useCallback(async (answerCorrect, givenAnswer) => {

        await submitResponse(question.id, givenAnswer)
        const newCorrectAnswers = [...correctAnswers]
        newCorrectAnswers[qaIndex] = answerCorrect
        setCorrectAnswers(newCorrectAnswers)


    }, [question, correctAnswers, qaIndex])


    const showPage = React.useCallback((i) => {

        setHidePage(true)

        setTimeout(() => {
            window.scrollTop = 0;
            setHidePage(false)
            setQAIndex(i)
        }, 50)
    }, [setHidePage, setQAIndex])

    const onNext = React.useCallback(() => showPage(qaIndex + 1), [showPage, qaIndex])

    const renderPage = () => {

        if (hidePage)
            return <div/>

        if (qaIndex == null) {
            return (
                <Welcome onStart={async () => {
                    await submitResponse(null, null)
                    showPage(0)
                }} content={staticTexts['welcome']}/>
            )
        }

        if (qaIndex === questions.length) {
            const score = correctAnswers.filter(v => !!v).length
            let scoreText = null
            // eslint-disable-next-line
            scoreTexts.map((v) => {
                if (score <= v['max_score']) {
                    scoreText = v['finish_text']
                }
            })

            const shareText = `Mijn score is ${score} van de ${questions.length} vragen goed. Doe de RES-TEST! Wat weet jij over de energietransitie in jouw regio?`
            return <FinishPage content={staticTexts['finish']} score={score} nrQuestions={questions.length}
                               scoreText={scoreText} shareText={shareText} avgScore={responseData.average_score}/>
        }



        return (
            <QuestionAnswer
                {...question}
                onAnswer={onAnswer}
                onNext={onNext}
                isLastQuestion={qaIndex === (questions.length - 1)}
            />
        )

    }

    const renderTitle = () => {

        if (hidePage)
            return <div/>

        if (qaIndex == null)
            return <h1>Welkom bij de RES kennistest</h1>

        if (qaIndex === questions.length)
            return <h1>Score</h1>

        if (correctAnswers[qaIndex] !== undefined) {
            return (
                <div className="answer-feedback">
                    <img src={correctAnswers[qaIndex] ? imgIconRight : imgIconWrong} alt={correctAnswers[qaIndex] ? 'Goed' : 'Fout'}/>
                    <h1>{qaIndex + 1}:{questions.length}</h1>
                </div>
            )
        }

        return <h1>{questions[qaIndex].title}</h1>
    }

    return (
        <div className="App">

            <header>
                <div className="container title">
                    {renderTitle()}
                    <img className="logo" src={logoImg} alt={'logo'}/>
                </div>
                <div className="container">
                    <div className="progress-bar">
                        <div className="correct"/>
                        <div className="fail"/>
                    </div>
                </div>
            </header>

            {renderPage()}

        </div>
    );
}

export default App;
